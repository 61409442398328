import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormInput from "../../../components/Inputs/FormInput";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import { objectsConstants } from "../../../constants/objects.constants";
import SelectableInput from "../../../sascComponents/inputs/SelectableInput";
import { atendimentoService } from "../../../services/atendimento.service";
import { convenioService } from "../../../services/convenio.service";
import { hospitalService } from "../../../services/hospital.service";

const CONVENIO = "convenio";
const LABEL_CONVENIO = "Convênio";
const REGISTRO_ANS_LABEL = "Registro ANS";

class DadosAutorizacaoAtendimentoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      numeroGuiaGlobal: 0,
      numeroGuiaAnoGlobal: 0,
    };
  }

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  /*isSenhaObrigatoria = () => {
    const { props } = this;
    let convenio = props.values && props.values.convenio ? props.values.convenio : null;
    let type = props.values && props.values.type ? objectsConstants.TIPOS_GUIA.find(element => element.label == props.values.type).id : null;
    let procedimentos = props.values && props.values.atendimentoExecucaos ? props.values.atendimentoExecucaos.map(element => element.codigoProcedimento) : null;
    let caraterAtendimento = props.values && props.values.caraterAtendimento ? props.values.caraterAtendimento.name : null;
    let plano = props.values && props.values.plano ? props.values.plano : null;

    convenioService.isSenhaObrigatoria(convenio, type, procedimentos, caraterAtendimento, plano).then(
      response => {
        this.setState({ senhaObrigatoria: response.data });
    },
    error => {
      console.error(error);
    });
  }*/
  
  render() {
    const tipoGuia = "";
    if (this.props.atendimento) {
      const tipoGuia = this.props.atendimento.type;
    }
    //this.isSenhaObrigatoria();
    const {
      //permissions,
      setFieldValue,
      values,
      //handleChange,
      errors,
      //setValues,
      viewMode,
    } = this.props;
    //const codigo = _.get(values, `atendimentoExecucaos[${0}].codigoProcedimento`);

    return (
      <div className="row section-form">
          {values&&values.justificativaCancelamento&&viewMode&&(

              <div className="form-group w-100" name="justificativaCancelamento"><label htmlFor="acomodacao"
                                                                                        className="">Justificativa para guia recusada:</label>
                  <p className="form-view ">{values.justificativaCancelamento}</p></div>


          )}
          {this.props.user.cooperativa.permiteAlterarNumeroAtendimento&&(
              <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                              name={"numeroAtendimento"}
                              label={"Nº do atendimento"}
                              value={values.numeroAtendimento}
              >
                  <InputViewEdit
                      label={"Nº do atendimento"}
                      component={FormInput}
                      onChange={(name, date) => {
                          setFieldValue(name, date);
                      }}
                      onBlur={() => this.sendToStorange(values)}
                      name={"numeroAtendimento"}
                      placeholder=""
                      type={"text"}
                      viewMode={viewMode}
                      value={values.numeroAtendimento?values.numeroAtendimento.replace(/\D/g, ''):values.numeroAtendimento}
                      erroMensagem={errors.numeroAtendimento}
                      required={_.get(this.props.user, 'cooperativa.permiteAlterarNumeroAtendimento', true)}
                  />
              </SelectableInput>               )}
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className=""
          name={CONVENIO}
          label={LABEL_CONVENIO}
          value={values.convenio && values.convenio.nome}
        >
          <InputViewEdit
            label={"Convênio"}
            required={true}
            component={FormSelectInput}
            service={convenioService.findAll}
            className={"destaque"}
            sortKey={"sigla"}
            id={CONVENIO}
            name={CONVENIO}
            returnFullObject={true}
            erroMensagem={_.get(errors, "convenio.id")}
            searchable={true}
            labelKey={"sigla"}
            valueKey={"id"}
            defaultValue={values.convenio ? values.convenio.nome : "--"}
            value={values.convenio}
            parent={{estabelecimento:values.estabelecimento,tipoGuia:this.props.atendimento.type}}
            viewMode={viewMode}
            onChange={(name, value) => {

              hospitalService.findHopistaisRegraConvenio(value.id,values.type).then(r => {
                if (r.data) {
                  if(value.instrucoesAtendimento){
                    this.props.error({message:value.instrucoesAtendimento+"<br><h2><strong>Este convênio autoriza atendimentos somente nos hospitais: "+r.data+"</strong></h2>",html:true,time:150000})
                  }else{
                    this.props.error({message:"<h2><strong>ATENÇÃO!</strong></h2><h2><strong>Este convênio autoriza atendimentos somente nos hospitais: "+r.data+"</strong></h2>",html:true,time:100000})
                  }
                }else{
                  if(value.instrucoesAtendimento){
                    this.props.error({message:value.instrucoesAtendimento,html:true,time:100000})
                  }
                }
              })


              if (value.procedimentoPadraoGuiaSadt) {
                _.set(values, 'atendimentoExecucaos[0].procedimento', value.procedimentoPadraoGuiaSadt);
                _.set(values, 'atendimentoExecucaos[0].codigoProcedimento', value.procedimentoPadraoGuiaSadt.codigo);
                _.set(values, 'atendimentoExecucaos[0].descricao', value.procedimentoPadraoGuiaSadt.descricao);
                setFieldValue('atendimentoExecucaos[0].procedimento', value.procedimentoPadraoGuiaSadt);
                setFieldValue('atendimentoExecucaos[0].codigoProcedimento', value.procedimentoPadraoGuiaSadt.codigo);
                setFieldValue('atendimentoExecucaos[0].descricao', value.procedimentoPadraoGuiaSadt.descricao);
                setFieldValue(`atendimentoProcedimentos[0].procedimento`, value.procedimentoPadraoGuiaSadt);
                setFieldValue(`atendimentoProcedimentos[0].codigoProcedimento`, value.procedimentoPadraoGuiaSadt.codigo);
                setFieldValue(`atendimentoProcedimentos[0].descricao`, value.procedimentoPadraoGuiaSadt.descricao);
              }

              _.set(values,'hospitalLocal',"");
              
                
                            if (value.caracteresInicioSenha && !_.startsWith(values.senha, value.caracteresInicioSenha)) {
                                _.set(values, 'senha', value.caracteresInicioSenha + _.get(values, 'senha', ""));
                            }


                            if (!value.numeroGuiaObrigatoria) {
                                if(value.reiniciarnumeroGuiaAno){
                                    if(this.state.numeroGuiaAnoGlobal > 0){
                                        setFieldValue('numeroGuia', this.state.numeroGuiaAnoGlobal);
                                        setFieldValue('numeroGuiaAutomatica', this.state.numeroGuiaAnoGlobal);
                                        setFieldValue('numeroGuiaAno', true);
                                    }else{
                                      atendimentoService.getNumeroGuiaConvenio(value.id).then(result => {
                                        this.setState({ numeroGuiaAnoGlobal: result.data });
                                        setFieldValue('numeroGuia', result.data);
                                        setFieldValue('numeroGuiaAutomatica', result.data);
                                        setFieldValue('numeroGuiaAno', true);
                                      })
                                    }

                                }else{
                                    if(this.state.numeroGuiaGlobal > 0){
                                      setFieldValue('numeroGuia', this.state.numeroGuiaGlobal);
                                      setFieldValue('numeroGuiaAutomatica', this.state.numeroGuiaGlobal);
                                      setFieldValue('numeroGuiaAno', false);
                                  }else{
                                    atendimentoService.getNumeroGuia().then(result => {
                                      this.setState({ numeroGuiaGlobal: result.data });
                                      setFieldValue('numeroGuia', result.data);
                                      setFieldValue('numeroGuiaAutomatica', result.data);
                                      setFieldValue('numeroGuiaAno', false);
                                    })
                                  }

                                }
  
                            }


                            /*
                            if (!value.numeroGuiaObrigatoria) {
                                if (values.numeroGuiaAutomatica) {
                                    setFieldValue('numeroGuia', values.numeroGuiaAutomatica);
                                } else {

                                if(value.reiniciarnumeroGuiaAno){
                                      atendimentoService.getNumeroGuiaConvenio(value.id).then(result => {
                                          setFieldValue('numeroGuia', result.data);
                                          setFieldValue('numeroGuiaAutomatica', result.data);
                                          setFieldValue('numeroGuiaAno', result.data);
                                      })

                                }else{
                                    atendimentoService.getNumeroGuia().then(result => {
                                      setFieldValue('numeroGuia', result.data);
                                      setFieldValue('numeroGuiaAutomatica', result.data); 
                                    })
                                }
                                     
                                }
                            }
*/
                              if(!values.id){
                                setFieldValue('numeroCarteira', "");
                                setFieldValue('nomeBeneficiario', "");
                                setFieldValue('dataValidadeCarteira', "");
                                setFieldValue('cpf', "");
                                setFieldValue('dataNascimento', "");
                                }

                                setFieldValue(name, value);
                            }
                        }
                        onBlur={() => this.sendToStorange(values)}
                            />
                            </SelectableInput>
                            {values.cooperativa && values.cooperativa.utilizaComplementoConvenio && (
                            <SelectableInput viewMode={viewMode}
                            atendimento={values}
                            className=""
                            name={"complementoConvenio"}
                            label={"Complemento"}
                            value={values.complementoConvenio}
                            >
                            <InputViewEdit
                            label={"Complemento"}
                            required={values.convenio ? convenioService.complementoObrigatorio(values.convenio.tiposGuiaProduto, values.type) : false}
                            disabled={!values.convenio}
                            component={FormSelectInput}
                            parent={{type:values.type,convenio:values.convenio}}
                            service={convenioService.findComplementoConvenioPorTipoGuia}
                            sortKey={"nome"}
                            className={"destaque"}
                            id={"complementoConvenio"}
                            name={"complementoConvenio"}
                            returnFullObject={false}
                            erroMensagem={_.get(errors, "complementoConvenio")}
                            searchable={true}
                            labelKey={"descricao"}
                            valueKey={"descricao"}
                            defaultValue={values.complementoConvenio ? values.complementoConvenio : "--"}
                            value={values.complementoConvenio}
                            viewMode={viewMode}
                            onChange={(name, value) => {
                            setFieldValue(name, value);
                            }}
                            onBlur={() => this.sendToStorange(values)}
                            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                            />
                            </SelectableInput>
                            )}
                            <SelectableInput viewMode={viewMode}
                            atendimento={values}
                            className="fixo"
                            name={"registroAns"}
                            label={REGISTRO_ANS_LABEL}
                            value={_.get(values, "convenio.registroAns")}
                            >
                            <InputViewEdit
                            label={REGISTRO_ANS_LABEL}
                            required={false}
                            disabled={true}
                            component={FormInput}
                            onChange={(name, date) => {
                            setFieldValue(name, date);
                            }}
                            onBlur={() => this.sendToStorange(values)}
                            erroMensagem={errors.registroAns}
                            name={"registroAns"}
                            placeholder="Registro ANS"
                            type={"text"}
                            viewMode={viewMode}
                            value={_.get(values, "convenio.registroAns")}
                            />
                            </SelectableInput>
                            <SelectableInput viewMode={viewMode}
                            atendimento={values}
                            className="fixo8"
                            name={"guiaInternacao"}
                            label={"Nº da guia de internação"}
                            value={values.guiaInternacao}
                            >
                            <InputViewEdit
                            label={"Nº da guia de internação"}
                            component={FormInput}
                            onChange={(name, date) => {
                            setFieldValue(name, date);
                            }}
                            onBlur={() => this.sendToStorange(values)}
                            name={"guiaInternacao"}
                            placeholder="Nº da guia de internação"
                            id={"guiaInternacao"}
                            type={"text"}
                            viewMode={viewMode}
                            value={values.guiaInternacao}
                            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                            />
                            </SelectableInput>
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"senha"}
          label={"Senha"}
          value={values.senha}
        >
          <InputViewEdit
            label={"Senha"}
            component={FormInput}
            alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
            erroMensagem={errors.senha}
            className={"destaque"}
            onChange={(name, date) => {
              let value = "";
              if (date) {
                value = date.replace(/\s/g, '').toUpperCase();
              }
              setFieldValue(name, value);

              if (_.get(values, "convenio.copiarSenha")) {
                setFieldValue('numeroGuia', value);
              }
            }}
            onBlur={() => this.sendToStorange(values)}
            name={"senha"}
            placeholder="Senha"
            helpText={"Esta senha deve ser gerada no site do convênio, ou conforme orientações no site da cooperativa."}
            type={"text"}
            viewMode={viewMode}
            maxLength={
              values.convenio &&
                values.convenio.listaRegrasSenhaConvenio &&
                values.convenio.listaRegrasSenhaConvenio.find(lista => _.get(lista, 'tipoGuia.id') == objectsConstants.TIPOS_GUIA.find(type => _.get(type, 'label') === values.type).id)
                ? values.convenio.listaRegrasSenhaConvenio.find(lista => _.get(lista, 'tipoGuia.id') == objectsConstants.TIPOS_GUIA.find(type => _.get(type, 'label') === values.type).id).quantDigitosSenha
                :_.get(values, 'convenio.quantDigitosSenha', 200)
            }
            value={values.senha}
            //required={this.state.senhaObrigatoria}
            required={convenioService.isSenhaObrigatoria(
              _.get(values, 'convenio.tiposGuiaSenhaObrigatoria', false), 
              values.type,
              _.get(values, 'convenio.listaRegrasSenhaConvenio', false),
              values.caraterAtendimento, 
              values.atendimentoExecucaos,values.plano)}

          />
        </SelectableInput>

        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"numeroGuia"}
          label={"Nº da guia"}
          //value={values.numeroGuia}
        >

          <InputViewEdit
            label={"Nº da guia"}
            component={FormInput}
            disabled={values.convenio&&!values.convenio.numeroGuiaObrigatoria}
            onChange={(name, date) => {
                if(date){
                    date = date.replace(/\s/g, '');
                }
              setFieldValue(name, date);
              if (_.get(values, "convenio.copiarSenha")) {
                setFieldValue('senha', date);
              }
            }}
            onBlur={() => this.sendToStorange(values)}
            name={"numeroGuia"}
            required={_.get(values, 'convenio.numeroGuiaObrigatoria', true)}
            erroMensagem={errors.numeroGuia}
            placeholder="Nº da guia"
            type={"text"}
            viewMode={viewMode}
            value={values.numeroGuia}
          />
        </SelectableInput>
      </div>
    );
  }
}

const mapDispatch = ( {  alert: { error }, menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
    error: msg => error(msg),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { pendenciaOpen } = state.pendencia;

  return {
    loggedIn,
    alert,
    user,
    permissions,
    pendenciaOpen
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosAutorizacaoAtendimentoGuiaForm));
