//@flow
import { urlsConstants } from '../constants/url.constant';

export const midiaService = {
    url,
    urlBucket
};


function url(id) {
    return `${urlsConstants.MIDIA}${id}`;
}

function urlBucket(bucket,id){
    return `${urlsConstants.MIDIA}loadFullBucket/${bucket}/${id}`
}