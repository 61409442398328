//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Input, InputGroupAddon, InputGroupText, InputType, Label } from 'reactstrap';
import { translate } from '../../helpers/message.helper';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  viewPassword?: boolean,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string,
};

class FormInput extends PureComponent<Props, state> {
  _input: any;
  constructor(props) {
    super(props);
    this.state = {
      forceShowPassword: false
    };
    this.showHide = this.showHide.bind(this);
  }

  

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false,
    viewPassword: false
  };

  getInput = () => {
    return this._input;
  };

  showHide(e) {
    this.setState({
      forceShowPassword: !this.state.forceShowPassword
    });
  }
  render() {
    const { messages, name, alertMensagem } = this.props;
    
    let mensagemAlert;
    if (alertMensagem) {
      mensagemAlert= alertMensagem.find(element =>
        this.props.name.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.nomeCampo.toLowerCase())
          && (this.props.label===null?  this.props.placeholder.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.label.toLowerCase())
          : this.props.label.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.label.toLowerCase()))
      );
      }

    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate } = this.props;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName === name) {
          messageValidate = translate(message.message.code);
        }
      });
    }

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    const value = this.props.value ? this.props.value : "";
    return (
      <div className={`${this.props.required ? 'input-required' : ''}`}>
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}
          </React.Fragment>
        )}
        {mensagemAlert && (
          <React.Fragment>
            <i className="icon-exclamation" id={'help_' + mensagemAlert.id} />
            <ToolTipHelp
              target={'help_' + mensagemAlert.id}
              message={mensagemAlert.mensagemAlerta}
            />
          </React.Fragment>
        )}
        {this.props.helpText && (
          <React.Fragment>
            <i className="icon-duvida" id={'help_' + this.props.id} />
            <ToolTipHelp
              target={'help_' + this.props.id}
              message={this.props.helpText}
            />
          </React.Fragment>
        )}
        <div className={this.props.viewPassword ? 'input-group' : ''}>
          <Input
            type={!this.state.forceShowPassword ? this.props.type : 'text'}
            id={this.props.id ? this.props.id : this.props.name}
            name={this.props.name}
            value={value}
            onBlur={this.props.onBlur}
            onChange={e => {
              const { name, type, value, checked } = e.currentTarget;
              this.props.onChange(name, value, type, checked);
            }}
            required={this.props.required}
            placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
            disabled={this.props.disabled ? this.props.disabled : false}
            maxLength={this.props.maxLength}
            innerRef={input => {
              this._input = input;
            }}
            onKeyPress={this.props.onKeyPress}
            className={`${this.props.className} ${
              messageValidate ? 'is-invalid' : ''
              }`}
            autoComplete="off"
          >
            {this.props.children}
          </Input>
          <Input
              type={"hidden"}
              id={this.props.id ? this.props.id : this.props.name}
              name={this.props.name}
              value={value}
              onBlur={this.props.onBlur}
              onChange={e => {
                const { name, type, value, checked } = e.currentTarget;
                this.props.onChange(name, value, type, checked);
              }}
              required={this.props.required}
              placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
              disabled={this.props.disabled ? this.props.disabled : false}
              maxLength={this.props.maxLength}
              innerRef={input => {
                this._input = input;
              }}
              onKeyPress={this.props.onKeyPress}
              className={`${this.props.className} ${
                  messageValidate ? 'is-invalid' : ''
              }`}
              autoComplete="off"
          >
            {this.props.children}
          </Input>
          {this.props.viewPassword && (
            <InputGroupAddon addonType="append">
              <InputGroupText>
                {' '}
                <span
                  className={
                    this.state.forceShowPassword ? 'icon-ocultar' : 'icon-ver'
                  }
                  onClick={this.showHide}
                ></span>
              </InputGroupText>
            </InputGroupAddon>
          )}
        </div>

        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id ? this.props.id : this.props.name}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(FormInput);
