import _, { values } from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import BtnLink from "../../components/Buttons/BtnLink";
import { FormGroup } from "../../components/FormGroup";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import CpfInput from "../../components/Inputs/CpfInput";
import FormInput from "../../components/Inputs/FormInput";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { formatterHelper } from "../../helpers/formatter.helper";
import { dateHelper } from "../../helpers/date.helper";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import CooperativaUsuarioForm from "./CooperativaUsuarioForm";
import DatePickerInput from "../../components/Inputs/DatePickerInput";

// import ConsultorioUsuarioForm from "./ConsultorioUsuarioForm";

class CooperativaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: true
    };
  }
  goToEdit = () => {
    this.props.history.push({
      pathname: "/cooperativa/edit/" + this.props.values.id,
      state: { entity: this.props.values }
    });
  };

  addUsuario = (setFieldValues, values) => {
    let usuarios = _.cloneDeep(values.usuarios);
    usuarios.unshift({ idKey: new Date().getTime() });
    setFieldValues("usuarios", usuarios);
  };

  addUsuarioAndEdit = (setFieldValues, values) => {
    this.setState({ viewMode: false });
  };

  removerUsuario = (setFieldValues, values, index) => {
    let usuarios = _.cloneDeep(values.usuarios);
    usuarios.splice(index, 1);
    setFieldValues("usuarios", usuarios);
    this.props.info({
      message: `Usuário removido com sucesso!`
    });
  };


  confirmCancel = setValues => {
    this.setState({ viewMode: true });
  };


  render() {
    const {
      values,
      setFieldValue,
      errors,
      isSubmitting,
      handleSubmit,
      viewMode,
    } = this.props; 
    return (
      <React.Fragment>
        <div className="item">
          <Card className="cardCrud content-form">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">Dados</div>
                <div className="px-sm-3">
                  {viewMode && (<><div className="d-flex flex-row justify-content-center justify-sm-content-end">
                    <button
                      type="button"
                      className="btn btn-light btDefaultGrid d-none d-lg-block"
                      onClick={() => this.props.history.push("/justificativas")}
                    >
                      <span className="icon-btn-editar"></span>
                      Justificativas de Reconsulta
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btDefaultGrid d-none d-lg-block"
                      onClick={() => this.props.history.push("/descricoesPendencia")}
                    >
                      <span className="icon-btn-editar"></span>
                      Descrições de Pendência
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={this.goToEdit}
                    >
                      <span className="icon-btn-editar"></span>Editar
                    </button>
                  </div></>)}
                </div>
              </div>
            </CardHeader>

            <CardBody className={"form-inside viewMode"}>
              <div className="row section-form">
                <h6 className="w-100">Cooperativa</h6>
              </div>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Nome"}
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Digite o nome da cooperativa"
                    value={values.nome}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.nome}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Sigla"}
                    type="text"
                    id="sigla"
                    name="sigla"
                    placeholder="Digite a sigla da cooperativa"
                    value={values.sigla}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.sigla}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup>
                  <InputViewEdit
                    component={CpfCnpjInput}
                    label={"CPF ou CNPJ"}
                    id="cnpj"
                    name="cnpj"
                    placeholder="Digite o CPF ou CNPJ"
                    value={values.cnpj}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.cnpj}
                    viewMode={viewMode}
                    required={true}
                    onBlur={() => {
                      if (values.cnpj != undefined) {
                        let match = values.cnpj.match(/\d+/g);

                        if (match != null && match.join("").length === 11) {
                          this.props.loading(true);
                          userService.existByCpf(values.cnpj).then(response => {
                            if (response.data) {
                              if (values.usuarios.length == 1) {
                                setFieldValue(`usuarios[${0}]`, response.data);
                              }
                            }
                            this.props.loading(false);
                          }, () => {
                            this.props.loading(false);

                          });
                        }
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"CNES"}
                    type="text"
                    id="cnes"
                    name="cnes"
                    placeholder="Digite o CNES"
                    value={values.cnes}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.cnes}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Código da operadora"}
                    type="text"
                    id="codigoOperadora"
                    name="codigoOperadora"
                    placeholder="Digite o código da operadora"
                    value={values.codigoOperadora}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.codigoOperadora}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={DatePickerInput}
                    label={"Data de cadastro da coperativa"}
                    id="dataEntrada"
                    name="dataEntrada"
                    value={values.dataEntrada}
                    defaultValue={values.dataEntrada ? dateHelper.format(values.dataEntrada,{mode:"DATE"}) : 'Data indisponível'}
                    placeholder="Digite a data de cadastro"
                    onChange={(name, value) => setFieldValue(name, value)}
                    erroMensagem={errors.dataEntrada}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="item mt-3">
          <Card>
            <CardBody className={"form-inside viewMode"}>
              <h6 className="w-100">Datas de última sincronização com o SASC</h6>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Data de ultima sincronização de convenios:"}
                    value={values.dataUltimaSyncConvenios ? values.dataUltimaSyncConvenios : "Data indisponivel"}
                    noSize={true}
                    viewMode={viewMode}
                    disabled
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Data de última sincronização de cooperados:"}
                    value={values.dataUltimaSyncCooperados ? values.dataUltimaSyncCooperados : "Data indisponível"}
                    noSize={true}
                    viewMode={viewMode}
                    disabled
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Data de última sincronização de lote atendimentos:"}
                    value={values.dataUltimaSyncLoteAtendimentos ? values.dataUltimaSyncLoteAtendimentos : "Data indisponível"}
                    noSize={true}
                    viewMode={viewMode}
                    disabled
                  />
                </FormGroup>
              </div>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Data de última sincronização de atendimentos:"}
                    value={values.dataUltimaSyncAtendimentos ? values.dataUltimaSyncAtendimentos : "Data indisponível"}
                    noSize={true}
                    viewMode={viewMode}
                    disabled
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Data de última sincronização de acomodações:"}
                    value={values.dataUltimaSyncAcomodacoes ? values.dataUltimaSyncAcomodacoes : "Data indisponível"}
                    noSize={true}
                    viewMode={viewMode}
                    disabled
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Data de última sincronização de hospitais:"}
                    value={values.dataUltimaSyncHospitais ? values.dataUltimaSyncHospitais : "Data indisponível"}
                    noSize={true}
                    viewMode={viewMode}
                    disabled
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="item mt-3">
          <Card>
            <CardBody className={"form-inside viewMode"}>
              <h6 className="w-100">Parametrizações referente ao atendimento</h6>
              <div className="row section-form">
              <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    options={objectsConstants.SIM_NAO}
                    label={"Guia física obrigatória:"}
                    id="guiaFisicaObrigatoria"
                    name="guiaFisicaObrigatoria"
                    returnFullObject={true}
                    defaultValue={
                      values.guiaFisicaObrigatoria &&
                      values.guiaFisicaObrigatoria.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.guiaFisicaObrigatoria &&
                              values.guiaFisicaObrigatoria.value
                              ? values.guiaFisicaObrigatoria
                                  .value
                              : values.guiaFisicaObrigatoria,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.guiaFisicaObrigatoria)
                        ? formatterHelper.getEnum(
                            values.guiaFisicaObrigatoria,
                            objectsConstants.SIM_NAO
                          )
                        : values.guiaFisicaObrigatoria
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} 
                    />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    options={objectsConstants.SIM_NAO}
                    label={"Campos de via e técnica obrigatórios:"}
                    id="viaTecnicaObrigatoria"
                    name="viaTecnicaObrigatoria"
                    returnFullObject={true}
                    defaultValue={
                      values.viaTecnicaObrigatoria &&
                      values.viaTecnicaObrigatoria.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.viaTecnicaObrigatoria &&
                              values.viaTecnicaObrigatoria.value
                              ? values.viaTecnicaObrigatoria
                                  .value
                              : values.viaTecnicaObrigatoria,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.viaTecnicaObrigatoria)
                        ? formatterHelper.getEnum(
                            values.viaTecnicaObrigatoria,
                            objectsConstants.SIM_NAO
                          )
                        : values.viaTecnicaObrigatoria
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} 
                    />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    options={objectsConstants.SIM_NAO}
                    label={"Permite alterar número de atendimento:"}
                    type="text"
                    id="permiteAlterarNumeroAtendimento"
                    name="permiteAlterarNumeroAtendimento"
                    returnFullObject={true}
                    defaultValue={
                      values.permiteAlterarNumeroAtendimento &&
                      values.permiteAlterarNumeroAtendimento.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.permiteAlterarNumeroAtendimento &&
                              values.permiteAlterarNumeroAtendimento.value
                              ? values.permiteAlterarNumeroAtendimento
                                  .value
                              : values.permiteAlterarNumeroAtendimento,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.permiteAlterarNumeroAtendimento)
                        ? formatterHelper.getEnum(
                            values.permiteAlterarNumeroAtendimento,
                            objectsConstants.SIM_NAO
                          )
                        : values.permiteAlterarNumeroAtendimento
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} 
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    options={objectsConstants.SIM_NAO}
                    label={"Utiliza complemento:"}
                    type="text"
                    id="utilizaComplementoConvenio"
                    name="utilizaComplementoConvenio"
                    returnFullObject={true}
                    defaultValue={
                      values.utilizaComplementoConvenio &&
                      values.utilizaComplementoConvenio.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.utilizaComplementoConvenio &&
                              values.utilizaComplementoConvenio.value
                              ? values.utilizaComplementoConvenio
                                  .value
                              : values.utilizaComplementoConvenio,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.utilizaComplementoConvenio)
                        ? formatterHelper.getEnum(
                            values.utilizaComplementoConvenio,
                            objectsConstants.SIM_NAO
                          )
                        : values.utilizaComplementoConvenio
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} />
                </FormGroup>
                
              </div>
              <div className="row section-form">
              <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Identificação do número guia SASC:"}
                    type="text"
                    id="identificacaoNumeroGuiaSasc"
                    name="identificacaoNumeroGuiaSasc"
                    value={values.identificacaoNumeroGuiaSasc}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.identificacaoNumeroGuiaSasc}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Validar reconsulta automaticamente:"}
                    type="text"
                    id="reconsultaAutomatica"
                    name="reconsultaAutomatica"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.reconsultaAutomatica &&
                      values.reconsultaAutomatica.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.reconsultaAutomatica &&
                              values.reconsultaAutomatica.value
                              ? values.reconsultaAutomatica
                                  .value
                              : values.reconsultaAutomatica,
                            objectsConstants.SIM_NAO
                          )
                    }
                    value={
                      _.isBoolean(values.reconsultaAutomatica)
                        ? formatterHelper.getEnum(
                            values.reconsultaAutomatica,
                            objectsConstants.SIM_NAO
                          )
                        : values.reconsultaAutomatica
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Bloquear data de atendimento para consulta:"}
                    type="text"
                    id="bloquearDataAtendimentoConsulta"
                    name="bloquearDataAtendimentoConsulta"
                    options={objectsConstants.SIM_NAO}
                    returnFullObject={true}
                    defaultValue={
                      values.bloquearDataAtendimentoConsulta &&
                      values.bloquearDataAtendimentoConsulta.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.bloquearDataAtendimentoConsulta &&
                              values.bloquearDataAtendimentoConsulta.value
                              ? values.bloquearDataAtendimentoConsulta
                                  .value
                              : values.bloquearDataAtendimentoConsulta,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.bloquearDataAtendimentoConsulta)
                        ? formatterHelper.getEnum(
                            values.bloquearDataAtendimentoConsulta,
                            objectsConstants.SIM_NAO
                          )
                        : values.bloquearDataAtendimentoConsulta
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Permite editar guia pendente:"}
                    type="text"
                    id="permiteEditarGuiaPendente"
                    name="permiteEditarGuiaPendente"
                    options={objectsConstants.SIM_NAO}
                    returnFullObject={true}
                    defaultValue={
                      values.permiteEditarGuiaPendente &&
                      values.permiteEditarGuiaPendente.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.permiteEditarGuiaPendente &&
                              values.permiteEditarGuiaPendente.value
                              ? values.permiteEditarGuiaPendente
                                  .value
                              : values.permiteEditarGuiaPendente,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.permiteEditarGuiaPendente)
                        ? formatterHelper.getEnum(
                            values.permiteEditarGuiaPendente,
                            objectsConstants.SIM_NAO
                          )
                        : values.permiteEditarGuiaPendente
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}
                  />
                </FormGroup>
              </div>
              <div className="row section-form">
              <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Gerar notificação de dias de atraso:"}
                    type="text"
                    id="diasGerarNotificacaoAtraso"
                    name="diasGerarNotificacaoAtraso"
                    value={values.diasGerarNotificacaoAtraso}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.diasGerarNotificacaoAtraso}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Via única obrigatória cirurgia:"}
                    id="viaUnicaObrigatoriaCirurgia"
                    name="viaUnicaObrigatoriaCirurgia"
                    options={objectsConstants.SIM_NAO}
                    returnFullObject={true}
                    defaultValue={
                      values.viaUnicaObrigatoriaCirurgia &&
                      values.viaUnicaObrigatoriaCirurgia.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.viaUnicaObrigatoriaCirurgia &&
                              values.viaUnicaObrigatoriaCirurgia.value
                              ? values.viaUnicaObrigatoriaCirurgia
                                  .value
                              : values.viaUnicaObrigatoriaCirurgia,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.viaUnicaObrigatoriaCirurgia)
                        ? formatterHelper.getEnum(
                            values.viaUnicaObrigatoriaCirurgia,
                            objectsConstants.SIM_NAO
                          )
                        : values.viaUnicaObrigatoriaCirurgia
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Permite envio eletrônico:"}
                    type="text"
                    id="permiteEnvioEletronico"
                    name="permiteEnvioEletronico"
                    options={objectsConstants.SIM_NAO}
                    returnFullObject={true}
                    defaultValue={
                      values.permiteEnvioEletronico &&
                      values.permiteEnvioEletronico.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.permiteEnvioEletronico &&
                              values.permiteEnvioEletronico.value
                              ? values.permiteEnvioEletronico
                                  .value
                              : values.permiteEnvioEletronico,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.permiteEnvioEletronico)
                        ? formatterHelper.getEnum(
                            values.permiteEnvioEletronico,
                            objectsConstants.SIM_NAO
                          )
                        : values.permiteEnvioEletronico
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Habilitar validação de auxiliares:"}
                    type="text"
                    id="validacaoAuxiliares"
                    name="validacaoAuxiliares"
                    options={objectsConstants.SIM_NAO}
                    returnFullObject={true}
                    defaultValue={
                      values.validacaoAuxiliares &&
                      values.validacaoAuxiliares.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.validacaoAuxiliares &&
                              values.validacaoAuxiliares.value
                              ? values.validacaoAuxiliares
                                  .value
                              : values.validacaoAuxiliares,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.validacaoAuxiliares)
                        ? formatterHelper.getEnum(
                            values.validacaoAuxiliares,
                            objectsConstants.SIM_NAO
                          )
                        : values.validacaoAuxiliares
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}  />
                </FormGroup>
              </div>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Utiliza hospital local na guia de consulta:"}
                    type="text"
                    id="utilizaHospitalLocal"
                    name="utilizaHospitalLocal"
                    options={objectsConstants.SIM_NAO}
                    returnFullObject={true}
                    defaultValue={
                      values.utilizaHospitalLocal &&
                      values.utilizaHospitalLocal.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.utilizaHospitalLocal &&
                              values.utilizaHospitalLocal.value
                              ? values.utilizaHospitalLocal
                                  .value
                              : values.utilizaHospitalLocal,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.utilizaHospitalLocal)
                        ? formatterHelper.getEnum(
                            values.utilizaHospitalLocal,
                            objectsConstants.SIM_NAO
                          )
                        : values.utilizaHospitalLocal
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}/>
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Contém status Não Faturado:"}
                    type="text"
                    id="contemStatusNaoFaturado"
                    name="contemStatusNaoFaturado"
                    options={objectsConstants.SIM_NAO}
                    returnFullObject={true}
                    defaultValue={
                      values.contemStatusNaoFaturado &&
                      values.contemStatusNaoFaturado.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.contemStatusNaoFaturado &&
                              values.contemStatusNaoFaturado.value
                              ? values.contemStatusNaoFaturado
                                  .value
                              : values.contemStatusNaoFaturado,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.contemStatusNaoFaturado)
                        ? formatterHelper.getEnum(
                            values.contemStatusNaoFaturado,
                            objectsConstants.SIM_NAO
                          )
                        : values.contemStatusNaoFaturado
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}/>
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}                    
                    label={"Exibir somente convenios ativos:"}
                    type="text"
                    id="somenteConveniosAtivos"
                    name="somenteConveniosAtivos"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.somenteConveniosAtivos &&
                      values.somenteConveniosAtivos.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.somenteConveniosAtivos &&
                              values.somenteConveniosAtivos.value
                              ? values.somenteConveniosAtivos
                                  .value
                              : values.somenteConveniosAtivos,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.somenteConveniosAtivos)
                        ? formatterHelper.getEnum(
                            values.somenteConveniosAtivos,
                            objectsConstants.SIM_NAO
                          )
                        : values.somenteConveniosAtivos
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="item mt-3">
          <Card>
            <CardBody className={"form-inside viewMode"}>
              <h6 className="w-100">Parametrizações referente ao lote</h6>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Enviar lote para faturamento ao criar:"}
                    type="text"
                    id="enviarLoteFaturamentoAoCriar"
                    name="enviarLoteFaturamentoAoCriar"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.enviarLoteFaturamentoAoCriar &&
                      values.enviarLoteFaturamentoAoCriar.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.enviarLoteFaturamentoAoCriar &&
                              values.enviarLoteFaturamentoAoCriar.value
                              ? values.enviarLoteFaturamentoAoCriar
                                  .value
                              : values.enviarLoteFaturamentoAoCriar,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.enviarLoteFaturamentoAoCriar)
                        ? formatterHelper.getEnum(
                            values.enviarLoteFaturamentoAoCriar,
                            objectsConstants.SIM_NAO
                          )
                        : values.enviarLoteFaturamentoAoCriar
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} 
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Nome do status de retorno do lote:"}
                    type="text"
                    id="nomeStatusRetornoLote"
                    name="nomeStatusRetornoLote"
                    value={values.nomeStatusRetornoLote}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.nomeStatusRetornoLote}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Enviar notificação de lote com erro:"}
                    type="text"
                    id="enviarNotificacaoLoteErro"
                    name="enviarNotificacaoLoteErro"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.enviarNotificacaoLoteErro &&
                      values.enviarNotificacaoLoteErro.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.enviarNotificacaoLoteErro &&
                              values.enviarNotificacaoLoteErro.value
                              ? values.enviarNotificacaoLoteErro
                                  .value
                              : values.enviarNotificacaoLoteErro,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.enviarNotificacaoLoteErro)
                        ? formatterHelper.getEnum(
                            values.enviarNotificacaoLoteErro,
                            objectsConstants.SIM_NAO
                          )
                        : values.enviarNotificacaoLoteErro
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} 
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Remoção automática do lote:"}
                    type="text"
                    id="remocaoAutomatica"
                    name="remocaoAutomatica"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.remocaoAutomatica &&
                      values.remocaoAutomatica.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.remocaoAutomatica &&
                              values.remocaoAutomatica.value
                              ? values.remocaoAutomatica
                                  .value
                              : values.remocaoAutomatica,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.remocaoAutomatica)
                        ? formatterHelper.getEnum(
                            values.remocaoAutomatica,
                            objectsConstants.SIM_NAO
                          )
                        : values.remocaoAutomatica
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true} 
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="item mt-3">
          <Card>
            <CardBody className={"form-inside viewMode"}>
              <h6 className="w-100">Parametrizações referente ao protocolo</h6>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Gerar protocolo sem limite convênio:"}
                    id="geraProtocoloSemLimiteConvenio"
                    name="geraProtocoloSemLimiteConvenio"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.geraProtocoloSemLimiteConvenio &&
                      values.geraProtocoloSemLimiteConvenio.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.geraProtocoloSemLimiteConvenio &&
                              values.geraProtocoloSemLimiteConvenio.value
                              ? values.geraProtocoloSemLimiteConvenio
                                  .value
                              : values.geraProtocoloSemLimiteConvenio,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.geraProtocoloSemLimiteConvenio)
                        ? formatterHelper.getEnum(
                            values.geraProtocoloSemLimiteConvenio,
                            objectsConstants.SIM_NAO
                          )
                        : values.geraProtocoloSemLimiteConvenio
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Imprimir protocolo por ordem de seleção:"}
                    id="impressaoProtocoloOrdemSelecao"
                    name="impressaoProtocoloOrdemSelecao"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.impressaoProtocoloOrdemSelecao &&
                      values.impressaoProtocoloOrdemSelecao.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.impressaoProtocoloOrdemSelecao &&
                              values.impressaoProtocoloOrdemSelecao.value
                              ? values.impressaoProtocoloOrdemSelecao
                                  .value
                              : values.impressaoProtocoloOrdemSelecao,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.impressaoProtocoloOrdemSelecao)
                        ? formatterHelper.getEnum(
                            values.impressaoProtocoloOrdemSelecao,
                            objectsConstants.SIM_NAO
                          )
                        : values.impressaoProtocoloOrdemSelecao
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Gerar protocolo por guia:"}
                    id="gerarProtocoloPorGuia"
                    name="gerarProtocoloPorGuia"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.gerarProtocoloPorGuia &&
                      values.gerarProtocoloPorGuia.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.gerarProtocoloPorGuia &&
                              values.gerarProtocoloPorGuia.value
                              ? values.gerarProtocoloPorGuia
                                  .value
                              : values.gerarProtocoloPorGuia,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.gerarProtocoloPorGuia)
                        ? formatterHelper.getEnum(
                            values.gerarProtocoloPorGuia,
                            objectsConstants.SIM_NAO
                          )
                        : values.gerarProtocoloPorGuia
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="item mt-3">
          <Card>
            <CardBody className={"form-inside viewMode"}>
              <h6 className="w-100">Parametrizações à Integração de atendimentos</h6>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Utiliza integração de atendimento:"}
                    id="utilizarIntegracaoAtendimento"
                    name="utilizarIntegracaoAtendimento"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.utilizarIntegracaoAtendimento &&
                      values.utilizarIntegracaoAtendimento.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.utilizarIntegracaoAtendimento &&
                              values.utilizarIntegracaoAtendimento.value
                              ? values.utilizarIntegracaoAtendimento
                                  .value
                              : values.utilizarIntegracaoAtendimento,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.utilizarIntegracaoAtendimento)
                        ? formatterHelper.getEnum(
                            values.utilizarIntegracaoAtendimento,
                            objectsConstants.SIM_NAO
                          )
                        : values.utilizarIntegracaoAtendimento
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Exibir ícone de integração:"}
                    id="exibirIconeIntegracao"
                    name="exibirIconeIntegracao"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.exibirIconeIntegracao &&
                      values.exibirIconeIntegracao.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.exibirIconeIntegracao &&
                              values.exibirIconeIntegracao.value
                              ? values.exibirIconeIntegracao
                                  .value
                              : values.exibirIconeIntegracao,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.exibirIconeIntegracao)
                        ? formatterHelper.getEnum(
                            values.exibirIconeIntegracao,
                            objectsConstants.SIM_NAO
                          )
                        : values.exibirIconeIntegracao
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="item mt-3">
          <Card>
            <CardBody className={"form-inside viewMode"}>
              <h6 className="w-100">Parametrizações à geração de relatórios</h6>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={SelectInput}
                    label={"Gerar relatório por status encaminhamento completo:"}
                    type="text"
                    id="gerarRelatorioStatusEncaminhamentoCompleto"
                    name="gerarRelatorioStatusEncaminhamentoCompleto"
                    returnFullObject={true}
                    options={objectsConstants.SIM_NAO}
                    defaultValue={
                      values.gerarRelatorioStatusEncaminhamentoCompleto &&
                      values.gerarRelatorioStatusEncaminhamentoCompleto.value ==
                        false
                        ? "Não"
                        : formatterHelper.getLabelEnum(
                            values.gerarRelatorioStatusEncaminhamentoCompleto &&
                              values.gerarRelatorioStatusEncaminhamentoCompleto.value
                              ? values.gerarRelatorioStatusEncaminhamentoCompleto
                                  .value
                              : values.gerarRelatorioStatusEncaminhamentoCompleto,
                            objectsConstants.SIM_NAO
                          )
                          
                    }
                    value={
                      _.isBoolean(values.gerarRelatorioStatusEncaminhamentoCompleto)
                        ? formatterHelper.getEnum(
                            values.gerarRelatorioStatusEncaminhamentoCompleto,
                            objectsConstants.SIM_NAO
                          )
                        : values.gerarRelatorioStatusEncaminhamentoCompleto
                    }
                    labelKey={"label"}
                    valueKey={"value"}
                    onChange={(name, value) => setFieldValue(name, value.value)}
                    viewMode={viewMode}
                    apendRoot={true}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>


        <div className="item pt-3">
          <Card className="cardCrud">
            <CardHeader className=" d-flex align-items-center flex-column flex-sm-row b-bottom">
              <div className="flex-grow-1 d-flex align-items-center">
                <div className="mr-sm-auto card-title">Usuários</div>
              </div>
              <div className="buttons-right ml-auto">
                {this.props.showButtonAdd && (
                  <BtnLink
                    type={"button"}
                    className={""}
                    icone={"icon-btn-add"}
                    title={"Adicionar usuário"}
                    onSubmit={e => {
                      this.addUsuario(setFieldValue, values);
                      // }
                    }}
                    disabled={false}
                  />
                )}
              </div>
            </CardHeader>
            <CardBody className={"form-inside viewMode"}>
              {this.state.viewMode && (
                <div className="row section-form">
                  <h6 className="w-100">Usuários da cooperativa</h6>
                </div>
              )}
              {!this.state.viewMode && (
                <React.Fragment>
                  <div className="row section-form">
                    <h6 className="w-100 verde-destaque">Adicionar usuário</h6>
                  </div>
                </React.Fragment>
              )}
              {values &&
                values.usuarios &&
                values.usuarios.map((usuario, index) => {
                  return (
                    <React.Fragment
                      key={usuario.id}
                    >
                      <div className="row section-form">
                      <FormGroup className="">
                          <InputViewEdit
                            component={CpfInput}
                            label="CPF"
                            id={`usuarioCpf${index}`}
                            defaultValue={usuario.cpf}
                            name={`usuarios[${index}].cpf`}
                            value={usuario.cpf}
                            placeholder="Digite o CPF"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            viewMode={viewMode}
                            required={true}
                            disabled={usuario.id ? true : false}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].cpf`
                            )}
                            onBlur={() => {
                              if (usuario.cpf && usuario.cpf.length) {
                                this.props.loading(true);
                                userService.existByCpf(usuario.cpf).then(
                                  response => {
                                    if (response.data) {
                                      if (!values.usuarios.some(usuario => usuario.cpf !== response.data.cpf)
                                        || values.usuarios.findIndex(usuario => usuario.cpf === response.data.cpf) === index) {
                                        setFieldValue(
                                          `usuarios[${index}]`,
                                          response.data
                                        );
                                      } else {
                                        setFieldValue(
                                          `usuarios[${index}].cpf`,
                                          ''
                                        );
                                        this.props.error({
                                          message: `O usuário já esta vinculado à cooperativa!`
                                        });
                                      }
                                    }
                                    this.props.loading(false);
                                  },
                                  () => {
                                    this.props.loading(false);
                                  }
                                );
                              }
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <InputViewEdit
                            component={FormInput}
                            label={"Nome"}
                            type="text"
                            id={`usuarioNome[${index}]`}
                            name={`usuarios[${index}].nome`}
                            value={usuario.nome}
                            defaultValue={usuario.nome}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            placeholder="Digite o nome"
                            viewMode={viewMode}
                            required={true}
                            disabled={usuario.id ? true : false} 
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].nome`
                            )}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <InputViewEdit
                            component={FormInput}
                            label="Senha"
                            type="password"
                            placeholder={"Digite a Senha"}
                            id={`password`}
                            name={`usuarios[${index}].password`}
                            value={usuario.password}
                            required={values.id ? false : true}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            viewMode={viewMode}
                            defaultValue={"**********"}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].password`
                            )}
                            disabled={usuario.id ? true : false}
                            viewPassword={true}
                            noSize={true}
                          />
                        </FormGroup>
                        {!viewMode && (
                          <FormGroup className="central fixo">
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-excluir"}
                              title={"Excluir"}
                              onSubmit={e => {
                                  e.stopPropagation();
                                  ConfirmAcationService.confirmMsg(
                                    () => {
                                      this.removerUsuario(setFieldValue, values, index);
                                    },
                                    `Excluir Usuário `,
                                    "Você confirma que deseja remover este usuário?",
                                    null,
                                    "Não",
                                    "Sim",
                                    objectsConstants.TYPE_EXCLUIR
                                  );
                                }}
                              disabled={false}
                            />

                          </FormGroup>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
            </CardBody>
          </Card>
        </div>



        <div className="item">
          {!viewMode && (
            <div className="d-flex flex-row flex-sm-row pt-2 pb-3 justify-content-center">
              <button
                type="submit"
                className="btn btn-primary d-blokc d-sm-none"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Salvar
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  info: msg => info(msg)
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CooperativaForm));
