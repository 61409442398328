import { Can } from "@casl/react";
import * as _ from "lodash";
import React, { PureComponent } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup, Modal, ModalBody } from "reactstrap";
import FormSelectInput from "../../components/Inputs/FormSelectInput";
import { objectsConstants, cooperativaID } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import SumarioRepasse from "../../sascComponents/statusAtendimentos/SumarioRepasse";
import StatusAtendimentosCustom_desktop from "../../sascComponents/statusAtendimentosCustom/statusAtendimentosCustom_desktop";
import StatusAtendimentosCustom_mobile from "../../sascComponents/statusAtendimentosCustom/statusAtendimentosCustom_mobile";
import TopoFiltroMulti from "../../sascComponents/topo/TopoFiltroMulti";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { atendimentoService } from "../../services/atendimento.service";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import LinhaAcaoAtivarInativarAtendimento from "./LinhaAcaoAtivarInativarAtendimento";
import LotesSlider from "./LotesSlider";
import MenuFiltro from "./MenuFiltro";
import SumarioAuditoria from "../../sascComponents/statusAtendimentosCustom/SumarioAuditoria";
import AtendimentosEmAtraso from "./AtendimentosEmAtraso";
import BtnEncaminhamentoEmMassa from "../../components/Buttons/BtnEncaminhamentoEmMassa";
import SelectInput from "../../components/Inputs/SelectInput";
import { translate } from "../../helpers/message.helper";
import fechar from "../../img/icon_fechar_BLACK.svg";
import { notificacaoService } from "../../services/notificacao.service";
import { SubStatusService } from "../../services/SubStatus.service";
import AtendimentosEmAtrasoCooperativa from "./AtendimentosEmAtrasoCooperativa";
import { statusAtendimentoService } from "../../services/statusAtendimento.service";
import ToggleButton from '../../components/Buttons/ToggleButton';
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { loteAtendimentoService } from "../../services/loteAtendimento.service";
import { defaultService } from "../../services/defaultService";
import iconCriarLote from '../../img/icon_criarLote.svg';
import { enumTipoErrors, enumTipoMsgSucesso } from "../../constants/objects.constants";
import CheckBoxInput from "../../components/Inputs/CheckBoxInput";
import e from "cors";
let download = require('downloadjs');
const defaultInitFilter = [
  {
    path: "Atendimento.status.subStatus",
    fieldName: "situacao",
    fieldDescription: "Situacao",
    columnOrder: 2,
    searchableField: true,
    type: "ENUM",
    condition: { description: "=" }

  }
];
const columnsExport = [{
  "path": "Atendimento.status",
  "fieldName": "nome",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "/atendimento/getStatusByFiltroAtendimento",
  "fieldDescription": "Status",
  "type": "SELECT"
},
{
  "path": "Atendimento.atendimentoLote",
  "fieldName": "id",
  "onlyNumber": false,
  "searchEmpty": true,
  "emptyTextSearch": "Sem lote",
  "url": "",
  "fieldDescription": "Nº do lote",
  "type": "NUMBER"
},
{
  "path": "Atendimento",
  "fieldName": "numeroAtendimento",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "",
  "fieldDescription": "Nº do atendimento",
  "type": "STRING"
},
{
  "path": "Atendimento",
  "fieldName": "dataAtendimento",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "",
  "fieldDescription": "Data do atendimento ",
  "type": "DATE"
},
{
  "path": "Atendimento",
  "fieldName": "numeroProtocolo",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "",
  "fieldDescription": "Nº do protocolo ",
  "type": "STRING"
},
{
  "path": "Atendimento",
  "fieldName": "numeroGuia",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": null,
  "url": null,
  "fieldDescription": "Nº da Guia ",
  "type": "STRING"
},
{
  "path": "convenio",
  "fieldName": "convenio.nome",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": null,
  "url": "/atendimento/getConveniosPorAtendimento",
  "fieldDescription": "Convênio",
  "type": "SELECT"
},
{
  "path": "Atendimento.beneficiario",
  "fieldName": "nomeBeneficiario",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "",
  "fieldDescription": "Nome do beneficiário",
  "type": "STRING"
},
{
  "path": "Atendimento.beneficiario",
  "fieldName": "numeroCarteira",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "",
  "fieldDescription": "Numero carteirinha",
  "type": "STRING"
},
{
  "path": "Atendimento",
  "fieldName": "tipoGuia",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "/atendimento/getTiposGuia",
  "fieldDescription": "Tipos de Guias",
  "type": "SELECT"
},
{
  "path": "Atendimento",
  "fieldName": "justificativaExclusaoLote",
  "onlyNumber": false,
  "searchEmpty": false,
  "emptyTextSearch": "",
  "url": "",
  "fieldDescription": "Justificativa da Remoção do Lote",
  "type": "STRING"
},
{
  "path": "Atendimento",
  "fieldName": "possuiAnexo",
  "url": "/enum/getPossuiAnexo",
  "fieldDescription": "Possui Anexo",
  "type": "SELECT"
}
]
const fields = [
  {
    "path": "Atendimento.status",
    "fieldName": "nome",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "/atendimento/getStatusByFiltroAtendimento",
    "fieldDescription": "Status",
    "type": "SELECT"
  },
  {
    "path": "Atendimento.atendimentoLote",
    "fieldName": "id",
    "onlyNumber": false,
    "searchEmpty": true,
    "emptyTextSearch": "Sem lote",
    "url": "",
    "fieldDescription": "Nº do lote",
    "type": "NUMBER"
  },
  {
    "path": "Atendimento",
    "fieldName": "numeroAtendimento",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Nº do atendimento",
    "type": "STRING"
  },
  {
    "path": "Atendimento",
    "fieldName": "dataAtendimento",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Data do atendimento ",
    "type": "DATE"
  },
  {
    "path": "Atendimento",
    "fieldName": "numeroProtocolo",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Nº do protocolo ",
    "type": "STRING"
  },
  {
    "path": "Atendimento",
    "fieldName": "numeroGuia",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": null,
    "url": null,
    "fieldDescription": "Nº da Guia ",
    "type": "STRING"
  },
  {
    "path": "Atendimento.estabelecimentoConvenio.convenio.dadosConvenio",
    "fieldName": "sigla",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": null,
    "url": "/atendimento/getConveniosPorAtendimento",
    "fieldDescription": "Convênio",
    "type": "SELECT"
  },
  {
    "path": "Atendimento.beneficiario",
    "fieldName": "nomeBeneficiario",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Nome do beneficiário",
    "type": "STRING"
  },
  {
    "path": "Atendimento.beneficiario",
    "fieldName": "numeroCarteira",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Numero carteirinha",
    "type": "STRING"
  },
  {
    "path": "Atendimento",
    "fieldName": "dtype",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "/atendimento/getTiposGuia",
    "fieldDescription": "Tipos de Guia",
    "type": "SELECT"
  },
  {
    "path": "Atendimento",
    "fieldName": "justificativaExclusaoLote",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Justificativa da Remoção do Lote",
    "type": "STRING"
  },
  {
    "path": "Atendimento",
    "fieldName": "possuiEvolucaoClinica",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "/enum/getPossuiEvolucaoClinica",
    "fieldDescription": "Evolução Clínica",
    "type": "SELECT"
  },
  {
    "path": "Atendimento",
    "fieldName": "possuiAnexo",
    "url": "/enum/getPossuiAnexo",
    "fieldDescription": "Possui Anexo",
    "type": "SELECT"
  }
];

let filtros = [];

class AtendimentoList extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    let initFilter = defaultInitFilter;
    const extension = userService.getExtensionUser(this.props.user);
    const isAuditor = userService.isAuditor(this.props.user);
    if (extension && !isAuditor) {
      initFilter.push({
        path: "Atendimento.estabelecimentoConvenio.estabelecimento",
        fieldName: "id",
        fieldDescription: "Id",
        columnOrder: 2,
        searchableField: true,
        value: extension,
        type: "NUMBER",
        condition: { description: "=" }
      });
    }
    let filter = _.isEmpty(props.filtroAtendimento)
      ? {}
      : props.filtroAtendimento;
    let activeIndex = _.isEmpty(props.filtroAtendimento)
      ? null
      : props.filtroAtendimento.activeIndex;

    this.state = {
      filter,
      initFilter,
      totalAtendimentos: 0,
      minDateAtendimento: null,
      activeIndex,
      sumario: objectsConstants.SUMARIO_REPASSE,
      modalEstabelecimento: false,
      totalPorStatus: [],
      toogleModalRecebimentoProtocolo: false,
      atendimentosProtocolo: [],
      atendimentosProtocoloRecebidos: false,
      atendimentosProtocoloRecebidosEnviados: [],
      existeAtendimentoPendente: false,
      listaSubStatus: [],
      status: null,
      statusEncaminhamentoEmMassa: [],
      emMassaLote: false,
      toogleModalCriarLote: false,
      convenio: null,
      convenios: null,
      bar_code: false,
      toogleModalCriarProtocolo: false,
      convenioProtocolo: null
    };

    if (activeIndex != null) {
      initFilter[0].value = this.getValueStatus(activeIndex);
      filter.situacao = this.getValueStatus(activeIndex);
    }

    statusAtendimentoService.statusEncaminhamentoEmMassa().then(response => {
      if (response.data && response.data.length > 0) {
        this.setState({ statusEncaminhamentoEmMassa: response.data })
      }
    })

    if (this.props.user.cooperativa && this.props.user.cooperativa.id === cooperativaID.SANTACOOP.id) {
      filtros = fields;
    } else {
      filtros = fields.filter(filtro => filtro.fieldDescription !== "Evolução Clínica");
    }
  }

  componentDidMount() {
    this.getSubStatus();
    if (!_.isEmpty(this.props.filtroAtendimento) && this.dataTable) {
      this.dataTable.initData();
    }
    if (userService.isConsultorio(this.props.user)) {
      notificacaoService.findByConsultorio().then(r => {
        if (r.data) {
          this.props.warning({ message: r.data.titulo + '<br/>' + r.data.descricao, html: true, time: 100000 })
        }
      }).catch(error => {
        console.error('Error during service worker registration:', error);
      });
    }
    if (userService.isCooperado(this.props.user)) {
      notificacaoService.findByEspecialidade().then(r => {
        if (r.data) {
          this.props.warning({ message: r.data.titulo + '<br/>' + r.data.descricao, html: true, time: 100000 })
        }
      }).catch(error => {
        console.error('Error during service worker registration:', error);
      });
    }
  }

  getSubStatus() {
    SubStatusService.findSubStatusByCooperativa().then(response => {
      let listaSubStatus = response.data.map(iten => {
        return iten;
      });
      this.setState({ listaSubStatus }, () => {
        this.atualizaData(listaSubStatus);
      });
    })
  }

  atualizaSumario = (filter) => {
    if (this.getValueStatus(this.state.activeIndex) === objectsConstants.SUB_STATUS.AUDITORIA) {
      try {
        atendimentoService.findSumario(filter).then(
          data => {
            this.setState({ sumario: data.data });
          },
          error => {
            console.error("Erro ao montar o combo", error);
          }
        );
      } catch (error) {
        console.error("Erro ao fazer fetch", error);
        this.setState({ sumario: objectsConstants.SUMARIO_REPASSE });
      }
    }
  }

  atualizaData = (listaSubstatus) => {
    let filtro = {
      situacao: null,
      activeIndex: 0
    }

    Object.assign(filtro, this.state.filter);
    filtro.situacao = null;
    filtro.activeIndex = 0;


    this.props.loading(true);
    atendimentoService.countStatus(filtro).then(response => {
      let totalAtendimentos = 0;
      const minDateAtendimento = response.data.minDateAtendimento;
      let listaCountAtendimento = response.data.listaCountAtendimento;
      let result = _.filter(listaSubstatus, function (subStatus) {
        totalAtendimentos = 0;
        for (let i = 0; i < listaCountAtendimento.length; i++) {
          const count = listaCountAtendimento[i];
          totalAtendimentos += count.total;
          if (subStatus.nome === count.nome) {
            _.set(subStatus, 'count', count.total)
          }
        }
        return subStatus;

      })

      this.setState({
        listaSubstatus: result,
        totalAtendimentos,
        minDateAtendimento
      });
      this.props.loading(false);
    });
  };



  renderContent = () => {
    let ComponentStatus;
    if (isMobile) {
      ComponentStatus = StatusAtendimentosCustom_mobile;
    } else {
      ComponentStatus = StatusAtendimentosCustom_desktop;
    }

    return (

      <ComponentStatus
        onChangeStatus={this.onChangeStatus}
        activeIndex={this.state.activeIndex}
        filter={this.state.filter}
        cooperativa={this.props.user.cooperativa}
        user={this.props.user}
        listaSubStatus={this.state.listaSubStatus}
      >
        {this.getValueStatus(this.state.activeIndex) === objectsConstants.SUB_STATUS.REPASSADO
          && <SumarioRepasse sumario={this.state.sumario}></SumarioRepasse>}
        {(this.getValueStatus(this.state.activeIndex) === objectsConstants.SUB_STATUS.AUDITORIA
          || this.getValueStatus(this.state.activeIndex) === objectsConstants.SUB_STATUS.PROTOCOLADAS)
          && <SumarioAuditoria totalPorStatus={this.state.totalPorStatus}></SumarioAuditoria>}
      </ComponentStatus>
    );
  };

  atualizaStatusAuditoria = (initFilter) => {
    if (!this.getValueStatus(this.state.activeIndex)
      || this.getValueStatus(this.state.activeIndex) === objectsConstants.SUB_STATUS.AUDITORIA) {
      atendimentoService.totalAtendimentoPorStatus(initFilter, objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA).then((response) => {
        this.setState({ totalPorStatus: response.data })
      });
    }

    if (!this.getValueStatus(this.state.activeIndex)
      || this.getValueStatus(this.state.activeIndex) === objectsConstants.SUB_STATUS.PROTOCOLADAS) {
      atendimentoService.totalAtendimentoPorStatus(initFilter, objectsConstants.SITUACAO_ATENDIMENTO.PROTOCOLADAS).then((response) => {
        this.setState({ totalPorStatus: response.data })
      });
    }
  }

  onChangeStatus = (activeIndex) => {
    let initFilter = _.cloneDeep(this.state.initFilter);
    let filter = _.cloneDeep(this.state.filter);
    let value = this.getValueStatus(activeIndex);
    initFilter[0].value = value;
    filter.situacao = value;
    this.setState({ initFilter, activeIndex, filter }, () => {
      if (this.dataTable) this.dataTable.initData();
      let filter = _.cloneDeep(this.state.filter);
      filter.activeIndex = activeIndex;
      this.props.changeFilter(filter);
    });
  };

  changeFilter = filter => {
    if (filter.situacao && !filter.activeIndex) {
      delete filter["situacao"];
    }
    let initFilter = _.cloneDeep(defaultInitFilter);
    this.setState({ filter, initFilter, activeIndex: -1 }, () => {
      this.atualizaData(this.state.listaSubStatus);
      if (this.dataTable) this.dataTable.initData();
      this.props.changeFilter(filter);
      this.atualizaSumario(filter);
      this.atualizaStatusAuditoria(initFilter);
    });
  };

  changeFilterProps = props => {
    let filter = _.cloneDeep(this.state.filter);
    delete filter[props];
    this.changeFilter(filter);
  };

  changeEstabelecimento = (name, value) => {
    let filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter }, () => {
      this.props.changeFilter(filter);
    });

  }

  clearEstabelecimento() {
    let filter = _.cloneDeep(this.state.filter);
    _.unset(filter, "estabelecimento");
    this.setState({ filter }, () => { });
    this.toogleModalEstabelecimento();
  }

  async verificaPermissaoNovoAtendimento() {
    const isCooperado = userService.isCooperado(this.props.user)
    const isConsultorio = userService.isConsultorio(this.props.user)
    const isPerfilCooperativa = userService.isPerfilCooperativa()

    const exibirErro = (mensagem) => { this.props.error({ message: mensagem }); };

    //outros perfis (add regra caso exista)
    if (isPerfilCooperativa || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.SUDO){
      this.toogleModalEstabelecimento();
    }

    //perfil cooperado
    if (isCooperado) {
      const consultoriosValidos = await estabelecimentoService.findByFilterAtendimentoWhereCooperadoAtivo({});
      //sem estabelecimentos vinculados ou ativos
      if (consultoriosValidos.data == null || consultoriosValidos.data.length === 0) {
        //mensagem especifica Coopmed-rs
        if (this.props.user.cooperativa.id === 2) {
          this.props.error({message: 
            "<h2 class='h2-alert'> Atenção! Não é possível inserir novos atendimentos. Para criar consultório, <a href='https://coopmedrs.com.br/wp-content/uploads/2024/12/Manual-criacao-consultorio-Sistema-Sasc-Connect.pdf' target='_blank' class='link-estilizado'>clique aqui</a>.</h2>",
            html: true
          });
          return;
        } else {
          exibirErro('Atenção! Não é possível inserir novos atendimentos, pois você não possui vínculo ativo com consultório. Favor contate a cooperativa.');
          return;
        }
      } //1 estabelecimento vinculado e ativo
      else if (consultoriosValidos.data != null && consultoriosValidos.data.length === 1) {
        let estabelecimento = consultoriosValidos.data[0];
        let filter = _.cloneDeep(this.state.filter)
        _.set(filter, 'estabelecimento', estabelecimento);
        this.setState({ filter }, () => {
          this.props.changeFilter(filter);
          this.props.history.push("/guias/criar");
        })
      } // +1 consultorio vinculado e ativo
      else if (consultoriosValidos.data != null && consultoriosValidos.data.length != 0) {
        this.toogleModalEstabelecimento();
      }
    } //perfil consultorio
    else if ((this.state.filter && this.state.filter.estabelecimento) || isConsultorio) {
      let idEstabelecimento = (this.state.filter && this.state.filter.estabelecimento) ? this.state.filter.estabelecimento.id : this.props.user.perfilAtual.extensao;
      estabelecimentoService.validarEstabelecimento(idEstabelecimento).then(r => {
        if (r.data) {
          this.props.history.push("/guias/criar");
        } else {
          exibirErro('Atenção! Não é possível inserir novos atendimentos. Favor contate a cooperativa.');
          return;
        }
      });
    }
  }


  toogleModalEstabelecimento = () => {
    this.setState({ modalEstabelecimento: !this.state.modalEstabelecimento });
  }
  getBtnNovoAtendimento = () => {

    return (
      <button
        type="button"
        className="btn btn-light"
        onClick={() => {
          this.verificaPermissaoNovoAtendimento()
        }}
      >
        <span className="icon-btn-add"></span>Novo atendimento
      </button>
    );

  };

  getBtnSolicitaExame = () => {

    return (
      <button
        type="button"
        className="btn btn-light"
        style={{ color: "#00cb7d", marginLeft: '50px' }}
        onClick={() => {
          this.props.history.push("/solicitaexame");
        }}
      >
        <span className="icon-btn-add"></span>Solicitação de exames
      </button>
    );

  };

  getValueStatus(activeIndex) {
    let value;
    switch (activeIndex) {
      case 0:
        value = objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO;
        break;
      case 1:
        value = objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA;
        break;
      case 2:
        value = objectsConstants.SITUACAO_ATENDIMENTO.PROTOCOLADAS;
        break;
      case 3:
        value = objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE;
        break;
      case 4:
        value = objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA
        break;
      case 5:
        value = objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA;
        break;
      case 6:
        value = objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE;
        break;
      case 7:
        value = objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO;
        break;
      case 8:
        value = objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO;
        break;
      case 9:
        value = objectsConstants.SITUACAO_ATENDIMENTO.REPASSADO;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  getTopoFilter = () => {
    let filterMenu = _.omit(this.state.filter, ['situacao', 'activeIndex'])
    if (Object.getOwnPropertyNames(filterMenu).length === 0
      || (Object.getOwnPropertyNames(filterMenu).length === 1
        && Object.getOwnPropertyNames(filterMenu)[0] === 'activeIndex')) {
      return <TopoListagem showfilter={true} />
    } else {
      return <TopoFiltroMulti
        changeFilterProps={this.changeFilterProps}
        filter={this.state.filter}
      />
    }
  }
  toogleModalRecebimentoProtocolo = () => {
    this.setState({ emMassaLote: false });
    this.setState({ toogleModalRecebimentoProtocolo: !this.state.toogleModalRecebimentoProtocolo });
    this.setState({ atendimentosProtocolo: [] })
    this.setState({ bar_code: false });
  }

  toogleModalCriarProtocolo = () => {
    this.setState({ toogleModalCriarProtocolo: !this.state.toogleModalCriarProtocolo });
  }

  validarQuantidadeAtendimentosGeracaoProtocolo() {
    let idsAtendimentos = _.map(this.state.atendimentosProtocolo, 'id');
    if (objectsConstants.STATUS_ENCAMINHAMENTO.NOME === this.state.status.nome && idsAtendimentos.length > 100) {
      return true
    } else {
      return false
    }
  }

  changeAtendimentoProtocolo = (name, value) => {
    if (this.state.bar_code && this.state.atendimentosProtocolo && value) {
      if (this.state.atendimentosProtocolo.length === value.length) {
        this.props.warning({ message: "Atendimento já foi selecionado ou atendimento não encontrado." })
      }
    }
    this.setState({ atendimentosProtocolo: value })
  }
  toogleModalCriarLote = () => {
    this.setState({ toogleModalCriarLote: !this.state.toogleModalCriarLote });
  }
  canCreateLote = () => {
    let canCreate = true;
    this.state.atendimentosProtocolo.forEach(atendimento => {
      let situacao = _.get(atendimento, "status.situacao");
      if (situacao === objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO) {
        this.faturado = true;
      } else {
        this.faturado = false;
      }

      let canEdit = situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA || situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE;
      if (!canEdit) {
        canCreate = false;
      }
    });
    return true;
  };

  async validarPrazoGeracaoProtocolo(status) {
    this.props.loading(true);
    let idsAtendimentos = _.map(this.state.atendimentosProtocolo, 'id');
    let prazoGerarProtocolo = [];
    await atendimentoService.findAllAtendimentosPrazoGeracaoProtocolo(idsAtendimentos).then(response => {
      prazoGerarProtocolo.push(response.data);
    })
    this.props.loading(false)

    if (prazoGerarProtocolo.length > 0)
      return !prazoGerarProtocolo.some(ele => ele === false)
    else
      return true;

  };


  encaminharAtendimentosEmMassa = (status) => {

    this.props.loading(true);
    let idsAtendimentos = _.map(this.state.atendimentosProtocolo, 'id');
    let atendimentosRecebidos = [];
    this.setState({ atendimentosProtocoloRecebidosEnviados: idsAtendimentos })

    if (!this.state.emMassaLote) {
      if (status.nome === objectsConstants.GUIA_RECEBIDA) {

        if (this.state.atendimentosProtocoloRecebidos) {
          for (let index = 0; index < idsAtendimentos.length; index++) {
            if (this.state.atendimentosProtocoloRecebidosEnviados.includes(idsAtendimentos[index])) {
              atendimentosRecebidos.push(true);
            }
          }
        }

        if (atendimentosRecebidos.length > 0) {
          this.props.error({
            message: enumTipoErrors.ATENDIMENTO_NAO_PODE_ENCAMINHAR_DUPLICADO
          });
          this.props.loading(false);

        } else {

          atendimentoService.encaminharAtendimentosProtocol(idsAtendimentos).then(
            response => {
              this.props.success({
                message: enumTipoMsgSucesso.ATENDIMENTOS_ENCAMINHADOS
              });
              this.setState({ atendimentosProtocoloRecebidos: true })
              this.props.loading(false);
            },
            erros => {
              console.error(erros.response.data.messages);
              if (erros.response.status === 400) {
                if (erros.response.data && erros.response.data.messages) {
                  for (let i = 0; i < erros.response.data.messages.length; i++) {
                    this.props.error({
                      message:
                        translate(erros.response.data.messages[i].message.code)
                    });
                  }
                } else {
                  this.props.error({
                    message:
                      enumTipoErrors.ATENDIMENTO_NAO_PODE_ENCAMINHAR_PROTOCOLO + erros.response.data + ""
                  });
                }
              }
            }
          );
        }

      } else {
        this.encaminharAtendimentos(status, null);
        this.setState({ status: null });
        this.setState({ atendimentosProtocoloRecebidos: false })
      }
    } else {
      let convenios = _.uniqBy(_.map(this.state.atendimentosProtocolo, function (n) { return _.get(n, 'estabelecimentoConvenio.convenio') }), 'id');
      if (this.canCreateLote() && convenios.length === 1) {
        ConfirmAcationService.confirmMsg(
          () => {
            this.addAtendimenosInLote(this.state.atendimentosProtocolo);
          },
          "Novo lote",
          <span>
            Deseja realmente criar um novo lote com os atendimentos
            informados?
          </span>,
          <span className="verde-destaque">{`${this.state.atendimentosProtocolo.length} atendimentos digitados`}</span>,
          "Não",
          "Sim",
          objectsConstants.TYPE_FORWARD
        );
      } else {
        this.setState({ convenios, toogleModalCriarLote: true })
      }
      this.props.loading(false);
    }
  };
  existeAtendimentoPendente = () => {
    this.setState({ existeAtendimentoPendente: true })
  }
  changeStatus = (name, value) => {
    this.setState({ status: value });
  };

  changeMassaLote = (value) => {

    if (this.state.atendimentosProtocoloRecebidos) {
      this.setState({ emMassaLote: value });
      this.setState({ status: null });
      this.setState({ atendimentosProtocoloRecebidos: false })
      this.setState({ bar_code: false })
    } else {
      this.setState({ emMassaLote: value });
      this.setState({ atendimentosProtocolo: [] });
      this.setState({ atendimentosProtocoloRecebidosEnviados: [] });
      this.setState({ status: null });
      this.setState({ bar_code: false })
    }

  }


  encaminharAtendimentos = (status, justificativa) => {
    let idsAtendimentosPorOrdemDeSelecao;
    if (this.state.convenioProtocolo === null) {
      idsAtendimentosPorOrdemDeSelecao = _.map(this.state.atendimentosProtocolo, (item, index) => ({ idAtendimento: item.id, posicao: index }));
    } else {
      let atendimentosInProtocolo = _.filter(this.state.atendimentosProtocolo, (atendimento) => {
        return _.get(atendimento, 'estabelecimentoConvenio.convenio.id') === _.get(this.state.convenioProtocolo, 'id');
      })
      idsAtendimentosPorOrdemDeSelecao = _.map(atendimentosInProtocolo, (item, index) => ({ idAtendimento: item.id, posicao: index }));
    }
    this.setState({ convenioProtocolo: null })
    atendimentoService.encaminharAtendimentos(status.id, idsAtendimentosPorOrdemDeSelecao, justificativa).then(
      response => {
        this.props.success({
          message: `Atendimentos encaminhados para ${status.nome}`
        });
        var base64 = _.get(response, 'data.data');
        if (base64 != null) {
          let extension = response.data.nome.split(".").pop()
          this.setState({ numeroProtocoloEncam: response.data.nome.split(".")[0] });
          if (extension === "txt") {
            download(
              response.data.data,
              'atendimentosRetornados' + '.txt',
              'text/plain'
            );
          } else {
            let asciiString = atob(base64);
            let array = new Uint8Array(
              [...asciiString].map((char) => char.charCodeAt(0))
            );

            let file = new Blob([array], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        this.setState({ atendimentosProtocolo: [] });
        this.setState({ atendimentosProtocoloRecebidosEnviados: [] });
        this.toogleModalRecebimentoProtocolo();
        this.props.loading(false);
      },
      erros => {
        console.error(erros.response.data.messages);
        if (erros.response.status === 400) {
          if (erros.response.data && erros.response.data.messages) {
            for (let i = 0; i < erros.response.data.messages.length; i++) {
              this.props.error({
                message:
                  translate(erros.response.data.messages[i].message.code)
              });
            }
          } else {
            this.props.error({
              message:
                "Os atendimentos não pode ser encaminhado para Protocolo, pois existem pendências nos atendimentos. Atendimentos: " + erros.response.data + ""
            });
          }
        }
        this.props.loading(false);
      }
    );

  };

  addAtendimenosInLote = atendimentos => {
    const atendimentosProtocolo = this.state.atendimentosProtocolo;
    const convenio = atendimentos[0].estabelecimentoConvenio.convenio;
    const lote = { atendimentos, convenio };
    loteAtendimentoService.doSave(lote).then(
      response => {
        let id = lote.id
          ? lote.id
          : defaultService.getIdFromUrl(response.headers.location);
        let totalAtendimentos = lote.atendimentos.length;
        let totalLotes = parseInt((totalAtendimentos - 1) / 100);
        let ids = '' + id;
        for (let i = 1; i <= totalLotes; i++) {
          ids = (id - i) + ',' + ids;
        }
        this.props.success({
          message: `Lote(s) ${ids} criado(s) com sucesso!`
        });

        let atendimentosConvenios = [];
        for (let i = 0; i < atendimentosProtocolo.length; i++) {
          if (_.get(atendimentosProtocolo[i], "estabelecimentoConvenio.convenio.id") !== convenio.id) {
            atendimentosConvenios.push(atendimentosProtocolo[i]);
          }
        }
        this.setState({ atendimentosProtocolo: [] });
        this.setState({ atendimentosProtocoloRecebidosEnviados: [] });
        this.toogleModalRecebimentoProtocolo();
        this.props.loading(false);
      },
      erros => {
        console.error(erros.response);
        try {
          let response = erros.response.data;
          if (response && response.messages) {
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
              this.props.error({
                message: translate(erroItem.message.code)
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
        this.props.loading(false);
      }
    );

  };
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.toogleModalCriarLote}
          toggle={this.toggleCriarLote}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>


            <div className="confirm-ui css-alert-sasc w-100"><img src={iconCriarLote}
              alt="icon" /><h2>Novo lote</h2><p><b></b></p>
              <div className="row body-confirm"><span> Existem atendimentos com convênios diferentes, para criar o lote selecione o convenio desejado?</span>
              </div>
              <SelectInput
                className="min-width"
                name={"setor"}
                value={this.state.convenio}
                options={this.state.convenios}
                onChange={(name, value) => {
                  this.setState({ convenio: value })
                }}
                returnFullObject={true}
                labelKey={"nome"}
                valueKey={"id"}
                placeholder="Selecione o Convenio"
              />
              <button className="btn btn-secondary white mt-3 mb-4 mx-2" onClick={() => { this.toogleModalCriarLote() }}>Não</button>
              <button disabled={!this.state.convenio} dclassName="btn btn-primary white mt-3 mb-4 mx-2" onClick={() => {
                this.addAtendimenosInLote(_.filter(this.state.atendimentosProtocolo, (atendimento) => {
                  return _.get(atendimento, 'estabelecimentoConvenio.convenio.id') === _.get(this.state.convenio, 'id');
                })); this.toogleModalCriarLote();
              }}>Sim</button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.toogleModalRecebimentoProtocolo}
          toggle={this.toggle}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-11 text-center my-2">
                  <h2 className="font-weight-bold">{this.state.emMassaLote ? 'Gerar Lote' : 'Encaminhamento em massa'}</h2>
                </div>
                <div className="col-1">
                  <a onClick={() => {

                    ConfirmAcationService.confirmMsg(
                      () => {
                        this.toogleModalRecebimentoProtocolo();

                      },
                      "Atenção, ao fechar o pop up, os atendimentos selecionados serão apagados do campo. Deseja prosseguir?",

                      ` `,
                      ` `,
                      "Não",
                      "Sim",
                      objectsConstants.TYPE_WARNING
                    );

                  }}
                    className="btn-fechar d-flex justify-content-end">
                    <img src={fechar} alt="fechar" className="pointer" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="flex-grow px-3 m-auto text-center col-12">
                  <Can
                    I={objectsConstants.ALTERAR}
                    a={objectsConstants.CRUD_LOTES_CRIAR}
                    ability={this.props.permissions}
                  >
                    <ToggleButton
                      onClick={this.changeMassaLote}
                    />

                  </Can>
                </div>
                {!this.state.emMassaLote && (
                  <div className="flex-grow px-3 m-auto justify-content-center col-12">
                    <label>Status</label>
                    <SelectInput
                      className="min-width"
                      name={"status"}
                      placeholder={"Selecione o status"}
                      returnFullObject={true}
                      labelKey={"nome"}
                      valueKey={"id"}
                      options={this.state.statusEncaminhamentoEmMassa}
                      value={this.state.status}
                      onChange={this.changeStatus}
                    />
                  </div>)}
                {(this.state.status || this.state.emMassaLote) && (
                  <div className="flex-grow px-3 m-auto justify-content-center col-12">
                    <label>{this.state.atendimentosProtocolo ? this.state.atendimentosProtocolo.length : 0} Atendimentos selecionados</label>
                    <SelectInput
                      isMulti={true}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      minLength={6}
                      onChange={this.changeAtendimentoProtocolo}
                      name={'atendimentosProtocolo'}
                      id={'atendimentosProtocolo'}
                      value={this.state.atendimentosProtocolo}
                      valueKey={"id"}
                      labelKey={"numeroAtendimento"}
                      onFetchData={atendimentoService.findByEncaminhamentoEmMassa}
                      parent={this.state.status ? this.state.status : this.state.emMassaLote}
                      returnFullObject={true}
                      required={this.props.required}
                      clearable={true}
                      className={this.props.className}
                      placeholder={"Número do atendimento"}
                      autoClick={this.state.bar_code} //Seleciona o primeiro valor da lista
                    />
                  </div>)}
                {((this.state.status && (this.state.status.nome == objectsConstants.ENCAMINHADO_GUIA_FISICA_PARA_COOPERATIVA ||
                  this.state.status.nome == objectsConstants.GUIA_RECEBIDA)) || this.state.emMassaLote) &&
                  (<div className="flex-grow px-3 m-auto justify-content-center col-12">
                    <CheckBoxInput
                      className="mb-0 pb-1"
                      label="Realizar leitura do código de barras"
                      name="checkItem"
                      checked={this.state.bar_code}
                      onChange={(name, checked) =>
                        this.setState({ bar_code: checked })
                      }
                    />
                  </div>)}
                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    disabled={(this.state.status || this.state.emMassaLote)
                      && this.state.atendimentosProtocolo
                      && this.state.atendimentosProtocolo.length > 0 ? false : true}
                    onClick={async () => {
                      let convenios = _.uniqBy(_.map(this.state.atendimentosProtocolo, function (n) { return _.get(n, 'estabelecimentoConvenio.convenio') }), 'id');
                      if (this.state.status && this.state.status.nome == objectsConstants.STATUS_ENCAMINHAMENTO.NOME) {
                        if (await this.validarPrazoGeracaoProtocolo(this.state.status)) {
                          ConfirmAcationService.confirmMsgPrazoFaturaVencida(
                            () => {
                            },
                            "Existem guias com o prazo de faturamento vencido.",
                            <span>
                              {" "}
                              Guia vencida - prazo de faturamento superior a negociação com convênio. Favor entrar em contato com a cooperativa.
                              <br />
                              Para prosseguir com a geração do protocolo das demais guias, favor remover a(s) guia(s) vencida(s) e encaminhar novamente.
                            </span>,
                            null,
                            "OK",
                            null,
                            objectsConstants.TYPE_WARNING
                          );
                        } else if (this.validarQuantidadeAtendimentosGeracaoProtocolo()) {
                          this.props.error({
                            message:
                              "Não é possível gerar protocolo de mais de 100 atendimentos por vez."
                          });
                        } else if (!userService.getCurrentUser().cooperativa.geraProtocoloSemLimiteConvenio && convenios.length > 1) {
                          this.setState({ convenios, toogleModalCriarProtocolo: true })
                        } else {

                          if (this.state.status && this.state.status.nome) {
                            ConfirmAcationService.confirmMsg(
                              () => {
                                this.encaminharAtendimentosEmMassa(this.state.status);

                              },
                              "Encaminhar atendimentos",
                              <span>
                                Deseja realmente encaminhar os atendimentos para o status de{" "}
                                <span className="font-bold">
                                  {this.state.status.nome}
                                </span>
                                ?
                              </span>,
                              `${this.state.status.nome} `,
                              "Não",
                              "Sim",
                              objectsConstants.TYPE_FORWARD
                            );
                          } else {
                            this.encaminharAtendimentosEmMassa(this.state.status);
                          }
                        }
                      } else {
                        if (this.state.status && this.state.status.nome) {
                          ConfirmAcationService.confirmMsg(
                            () => {
                              this.encaminharAtendimentosEmMassa(this.state.status);

                            },
                            "Encaminhar atendimentos",
                            <span>
                              Deseja realmente encaminhar os atendimentos para o status de{" "}
                              <span className="font-bold">
                                {this.state.status.nome}
                              </span>
                              ?
                            </span>,
                            `${this.state.status.nome} `,
                            "Não",
                            "Sim",
                            objectsConstants.TYPE_FORWARD
                          );
                        } else {
                          this.encaminharAtendimentosEmMassa(this.state.status);
                        }
                      }
                    }
                    }
                  >
                    <span className={`icon-btn-check`}></span>
                    {!this.state.emMassaLote ? "Encaminhar" : "Gerar lote"}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.toogleModalCriarProtocolo}
          toggle={this.toogleModalCriarProtocolo}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <div className="confirm-ui css-alert-sasc w-100"><img src={iconCriarLote}
              alt="icon" /><h2>Novo protocolo</h2><p><b></b></p>
              <div className="row body-confirm"><span> Existem atendimentos com convênios diferentes, para criar o protocolo selecione o convenio desejado?</span>
              </div>
              <SelectInput
                className="min-width"
                name={"setor"}
                value={this.state.convenioProtocolo}
                options={this.state.convenios}
                onChange={(name, value) => {
                  this.setState({ convenioProtocolo: value })
                }}
                returnFullObject={true}
                labelKey={"nome"}
                valueKey={"id"}
                placeholder="Selecione o Convenio"
              />
              <button className="btn btn-secondary white mt-3 mb-4 mx-2" onClick={() => { this.toogleModalCriarProtocolo() }}>Não</button>
              <button disabled={!this.state.convenioProtocolo} dclassName="btn btn-primary white mt-3 mb-4 mx-2" onClick={() => {
                this.encaminharAtendimentosEmMassa(this.state.status);
                this.toogleModalCriarProtocolo();
              }}>Sim</button>
            </div>
          </ModalBody>
        </Modal>
        <div className="container-fluid container-com-topo">
          <TopoTitleComponente
            mainTitle={
              this.props.user.cooperativa && this.props.user.cooperativa.sigla
            }
            subTitle=" "
            canBack={false}
          />
          {this.getTopoFilter()}

          <MenuFiltro changeFilter={this.changeFilter}></MenuFiltro>

          <hr className="text-center w-75"></hr>

          <div className="background-lines">
            <div className="bg-riscos"></div>
            <div className="d-block d-sm-flex justify-content-between">
              <div className="btn-novo-atend text-center text-sm-left mt-sm-5 mr-sm-5">
                <Can
                  I={objectsConstants.EXECUTAR}
                  a={objectsConstants.BTN_EXAME}
                  ability={this.props.permissions}
                >
                  {(userService.isCooperado(this.props.user)) && (this.getBtnSolicitaExame())}
                </Can>
              </div>
              <div className="box-branco-atendimentos text-center">
                <h5 className="pt-3 mb-1">
                  <b>{this.state.totalAtendimentos} Atendimentos</b>
                </h5>
                {this.state.minDateAtendimento && (
                  <p className="font-italic txtGray">
                    Total realizados desde {this.state.minDateAtendimento}
                  </p>
                )}
              </div>
              <div className="btn-novo-atend text-center text-sm-right mt-sm-5 mr-sm-5">
                <Can
                  I={objectsConstants.ALTERAR}
                  a={objectsConstants.CRUD_ATENDIMENTOS_CRIAR}
                  ability={this.props.permissions}
                >
                  {this.getBtnNovoAtendimento()}
                </Can>
              </div>
            </div>
          </div>

          <div className="container-fluid container-com-topo mb-sm-3">
            {" "}
            {this.renderContent()}
          </div>
          {this.state.initFilter[0].value && (
            <Datatable
              listFields={filtros}
              columnsExport={columnsExport}
              ActionButton={BtnEncaminhamentoEmMassa}
              toogleProtocol={this.toogleModalRecebimentoProtocolo}
              showProtocol={this.state.toogleModalRecebimentoProtocolo}
              name={"atendimentos"}
              showCheckboxColumn={true}
              exportAtendimento={true}
              idRow={"id"}
              keyTagItem={'numeroAtendimento'}
              onRowClick={(field, rowData) => {
                let entitys = [];
                entitys.push(rowData);
                this.props.history.push({
                  pathname: "/guias",
                  state: { entitys }
                });
              }}
              classHeader={[
                "headerCenter",
                "headerCenter",
                "headerCenter",
                "headerCenter",
                "headerCenter",
                "headerCenter",
                "headerCenter",
                "headerCenter"
              ]}
              classColumn={[
                "colunaMin",
                "colunaMin",
                "colunaMin",
                "colunaMin",
                "colunaMin",
                "colunaMed",
                "colunaPeq",
                "colunaPeq",
                "colunaPeq"
              ]}
              onFilter={(filter) => { this.atualizaSumario(filter); this.atualizaStatusAuditoria(filter) }}
              service={atendimentoService}
              showAllColumnsFilter={false}
              initFilter={this.state.initFilter}
              filter={this.state.filter}
              ActionLine={LinhaAcaoAtivarInativarAtendimento}
              situacao={this.state.filter.situacao}
              Sumario={LotesSlider}
              wrappedComponentRef={c => (this.dataTable = c)}
            />
          )}
        </div>

        <Modal
          isOpen={this.state.modalEstabelecimento}
          toggle={this.toogleModalEstabelecimento}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-11 text-center my-2">
                  <h2 className="font-weight-bold">Selecionar estabelecimento</h2>
                </div>

              </div>


              <div className="row">
                <div className="flex-fill px-5 m-auto justify-content-center">
                  <FormGroup className='col-12'>
                    <FormSelectInput
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={this.changeEstabelecimento}
                      name={'estabelecimento'}
                      id={'estabelecimento'}
                      value={this.state.filter.estabelecimento}
                      valueKey={"id"}
                      labelKey={"nome"}
                      parent={{}}
                      service={estabelecimentoService.findByFilterAtendimentoWhereCooperadoAtivo}
                      returnFullObject={true}
                      required={true}
                      clearable={true}
                      placeholder={"Estabelecimento"}
                      label={``}
                    />
                  </FormGroup>

                </div>
              </div>
              <div className="row">
                <div className="flex-grow px-1 m-auto justify-content-center">

                  <button
                    className="btn btn-secondary white mt-3 mb-4 mx-2"
                    onClick={() => {
                      this.clearEstabelecimento()
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-primary white mt-3 mb-4 mx-2"
                    onClick={() => {
                      this.toogleModalEstabelecimento()
                      this.props.history.push("/guias/criar");
                    }}
                  >
                    Selecionar
                  </button>
                </div>

              </div>

            </div>
          </ModalBody>
        </Modal>
        {userService.getCurrentUser().cooperativa.diasGerarNotificacaoAtraso && (userService.isConsultorio(userService.getCurrentUser()) || userService.isCooperado(userService.getCurrentUser())) && (
          <AtendimentosEmAtraso existeAtendimentoPendente={this.existeAtendimentoPendente} />
        )}
        {userService.getCurrentUser().cooperativa.diasGerarNotificacaoAtraso && (userService.isPerfil(userService.getCurrentUser(), objectsConstants.ADMINISTRADOR) || userService.isPerfil(userService.getCurrentUser(), objectsConstants.PROTOCOLO) || userService.isPerfil(userService.getCurrentUser(), objectsConstants.AUDITOR_SEM_SETOR))
          &&
          (<AtendimentosEmAtrasoCooperativa existeAtendimentoPendente={this.existeAtendimentoPendente} />)
        }
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  filtro: { changeFilter },
  alert: { success, error, clear, warning },
  load: { loading },
}) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
  changeFilter: filter => changeFilter({ filtroAtendimento: filter }),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  warning: msg => warning(msg),
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;

  return {
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(AtendimentoList));
