import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// import DadosAtendimentoGuiaForm from "./componentes/guiaInternacao/DadosAtendimentoGuiaForm";
import DadosAutorizacaoAtendimentoGuiaForm from "./componentes/guiaInternacao/DadosAutorizacaoAtendimentoGuiaForm";
import DadosAutorizacaoForm from "./componentes/guiaInternacao/DadosAutorizacaoForm";
import DadosBeneficiarioGuiaForm from "./componentes/guiaInternacao/DadosBeneficiarioGuiaForm";
import DadosContratadoGuiaForm from "./componentes/guiaInternacao/DadosContratadoGuiaForm";
import DadosHipotesesDiagnosticos from "./componentes/guiaInternacao/DadosHipotesesDiagnosticos";
import DadosSolicitacaoOpmForm from "./componentes/guiaInternacao/DadosSolicitacaoOpmForm";
import DadosSolicitacaoProcedimentoGuiaForm from "./componentes/guiaInternacao/DadosSolicitacaoProcedimentoGuiaForm";
// import DadosExecucaoGuiaForm from "./componentes/guiaInternacao/DadosExecucaoGuiaForm";
// import DadosProfissionalExecutanteGuiaForm from "./componentes/guiaInternacao/DadosProfissionalExecutanteGuiaForm";
// import DadosSolicitacaoProcedimentoGuiaForm from "./componentes/guiaInternacao/DadosSolicitacaoProcedimentoGuiaForm";
import DadosSolicitanteGuiaForm from "./componentes/guiaInternacao/DadosSolicitanteGuiaForm";
import ValoresProcedimentosGuiaForm from "./componentes/guiaInternacao/ValoresProcedimentosGuiaForm";
import PassoaPassoItem from "./PassoaPassoItem";
import DadosProfissionalExecutanteGuiaForm from "./componentes/guiaInternacao/DadosProfissionalExecutanteGuiaForm";

class GuiaInternacaoForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      criarGuiaPassoPasso,
      errors,
      setFieldValue,
      values,
      handleChange,
      setValues,
      viewMode,
      etapaAtual,
      changeGuia,
      ...otherProps
    } = this.props;

    return (
      <React.Fragment>

        {/* PASSO 1 */}
        <PassoaPassoItem
          etapa={1}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosAutorizacaoAtendimentoGuiaForm
            errors={errors}
            user={this.props.user}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            atendimento={this.props.atendimento}
          />
          <DadosBeneficiarioGuiaForm
            errors={errors}
            setValues={setValues}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            changeGuia={changeGuia}
            {...otherProps}
          />
        </PassoaPassoItem>

        {/* PASSO 2 */}
        <PassoaPassoItem
          etapa={2}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosSolicitanteGuiaForm
            errors={errors}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            user={this.props.user}
            listUfsConselho={this.props.listUfsConselho}
            listConselho={this.props.listConselho}
          />
        </PassoaPassoItem>

        {/* PASSO 3 */}
        <PassoaPassoItem
          etapa={3}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosProfissionalExecutanteGuiaForm
                className={"section-form"}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                handleChange={handleChange}
                viewMode={viewMode}
                listUfsConselho={this.props.listUfsConselho}
                listConselho={this.props.listConselho}
                setValues={setValues}
                {...otherProps}
            />
          <DadosContratadoGuiaForm
            errors={errors}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />
          <DadosHipotesesDiagnosticos
            errors={errors}
            setValues={setValues}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            {...otherProps}
          />
        </PassoaPassoItem>

        {/* PASSO 4 */}
        <PassoaPassoItem
          etapa={4}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosSolicitacaoProcedimentoGuiaForm
            setValues={setValues}
            className={"section-form"}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />
            
          <DadosSolicitacaoOpmForm
            setValues={setValues}
            className={"section-form"}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />
        </PassoaPassoItem>

        {/* PASSO 5 */}
        <PassoaPassoItem
          etapa={5}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosAutorizacaoForm
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />
        </PassoaPassoItem>

        {/* PASSO 6 */}
        <PassoaPassoItem
          etapa={6}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <div className="w-100 h-20"></div>
          <ValoresProcedimentosGuiaForm
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />
        </PassoaPassoItem>

      </React.Fragment >

    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  pendencia: { togglePendencia, atendimentoAtual }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  togglePendencia: () => togglePendencia(),
  atendimentoAtual: atendimento => atendimentoAtual(atendimento)
});

function mapStateToProps(state) {
  const { pendenciaOpen, idAtendimentoAtual } = state.pendencia;
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;

  return {
    pendenciaOpen,
    idAtendimentoAtual,
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(GuiaInternacaoForm));
