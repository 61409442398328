import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { cooperativaService } from "../../services/cooperativa.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import LinhaAcaoCooperativa from "./LinhaAcaoCooperativa";


class CooperativaList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: { status: true },
      initFilter:[{}]
    };
  }

  handleRowClick = (fieldName, rowData) => {
    userService.setCooperativaAtualSudo(rowData.id).then(response => {
      const user = response.data;
      this.props.closeSelectPerfil({
        user, selectPerfilAuditor: false,
        selectPerfilAtual: false
      });
      this.props.history.push({
        pathname: "/cooperativa/view/" + rowData.id,
        state: { entity: rowData }
      });
    })
  };

  initFilterCooperativa = () => {
    return [{
      condition : { description: '=' },
      fieldName: "nome",
      onlyNumber: false,
      path: "Cooperativa",
      value: this.state.entity.nome
    }]
  }

  closeMenu = () => {
    this.props.showMenu();
  };
  render() {
    const { permissions, user, hideTopo } = this.props;

    return (
      <div className="container-fluid container-com-topo">
        {!hideTopo && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle="Cooperativa"
              subTitle=" "
              canBack={false}
            />
            <TopoListagem
              securityActionDo={objectsConstants.ALTERAR}
              securityActionOn={objectsConstants.CRUDCOOPERATIVAS_CADASTRAR}
              buttonActionClassName={"btn-light"}
              buttonAction={() => {
                this.props.history.push("/cooperativa/novo");
              }}
              securityActionIcon={"icon-btn-add"}
              securityActionName={"Nova cooperativa"}
            />

            {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.ADMINISTRADOR ||
              this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.PROTOCOLO) &&
              <div className="d-flex flex-row justify-content-center justify-sm-content-end">
                <button
                  type="button"
                  className="btn btn-light btDefaultGrid d-none d-lg-block"
                  onClick={() => this.props.history.push("/justificativas")}
                >
                  <span className="icon-btn-editar"></span>
                  Justificativas de Reconsulta
                </button>
                <button
                  type="button"
                  className="btn btn-light btDefaultGrid d-none d-lg-block"
                  onClick={() => this.props.history.push("/descricoesPendencia")}
                >
                  <span className="icon-btn-editar"></span>
                  Descrições de Pendência
                </button>
              </div>}

            <hr className="text-center w-75"></hr>

            <div className="background-lines">
              <div className="bg-riscos"></div>
            </div>
          </React.Fragment>
        )}

        {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.SUDO) && 
        <Datatable
          showCheckboxColumn={true}
          idRow={"id"}
          name={"Cooperativa(s)"}
          service={cooperativaService}
          ActionLine={LinhaAcaoCooperativa}
          onRowClick={this.handleRowClick}
          keyTagItem={"nome"}
          showAllColumnsFilter={false}
		   />
        }
    </div>)
  }
}


const mapDispatch = ({ menu: { showMenu, hideMenu }, authentication: { closeSelectPerfil } }) => ({
  showMenu: () => showMenu(),
  closeSelectPerfil: payload => closeSelectPerfil(payload),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CooperativaList));
